@import './fonts/IranSans/style.css';
@import "./LineIcons/WebFont/font-css/LineIcons.css";

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  direction: rtl;
  font-family: irsans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f9f9f9;
  -webkit-box-shadow: 1px 1px 4px 1px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    1px 1px 4px 1px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         1px 1px 4px 1px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

}

.header .logo, .sign-out a {
  display: flex;
}

.header .sign-out a {
  text-decoration: none;
  color: black;
}

.header .top-links a{
  text-decoration: none;
  color: #002e79;
  margin-right: 20px;
}

.header .top-links a:hover{
  text-decoration: underline;
}