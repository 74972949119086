
.mainCatDiv{
    list-style: none;
}

.mainCatDiv li{
    float: right;
    margin: 0 10px 0 0;
}

.mainCatDiv li div {
    padding: 3px 15px 3px 15px;
    cursor: pointer;
    border-bottom: 2px #7a7a7a solid;
}

.mainCatDiv li div.selected {
    border-bottom: 2px #00b0ff solid;
}

.tabPane{
    float: right;
    margin: 15px 10px 0 0;
    list-style: none;
    display: none;
}

.tabPane.selected{
    display: block;
}

.tabPane li {
    cursor: pointer;
    padding: 5px 15px 5px 15px;
    float: right;
}

.tabPane li.selected {
    background-color: khaki;
}
.tabPane li.subgroup.selected {
    background-color: khaki;
}.tabPane li.subgroup {
    background-color: lemonchiffon;
}
.tabPane li.subsubgroup.selected {
    background-color: #ffc000;
}.tabPane li.subsubgroup {
    background-color: #ffd246;
}

.product-name {
    background-color: rgba(180, 0, 0, 0.1);
}
.sell-price {
    background-color: rgba(0, 200, 0, 0.1);
}
.quantity {
    background-color: rgba(0, 0, 200, 0.1);
}
.sepidar_mojodi {
    background-color:#ffc00047;
}

.App {
    margin: 8px;
    direction: rtl;
    display: flex;
    flex-direction: column;
}

.updatePrice {
    color: darkgreen;
    position: absolute;
    top: 10px;
    left: 30px;
}

.updateError {
    color: red;
    position: absolute;
    top: 10px;
    left: 30px;
}

.history-list ul {
    list-style: none;
    margin-right: 15px;
    padding: 0;
}

.history-list ul li{
    padding: 6px;
    display: flex;
    margin-top: 3px;
}
.history-list ul li.even{
    background-color: #fff0f0;
}
.history-list ul li.odd{
    background-color: #fafafa;
}
.history-list ul li .item{
    margin-right: 20px;
}
.history-list ul li .item.name{
    width: 240px;
}
.history-list ul li .item.field{
    width: 100px;
}
.history-list ul li .item.date{
    width: 100px;
}

.addInvoiceTable thead tr{
    border: 1px #6c757d solid;
}

.errorMsg {
    padding: 5px;
    background-color: #ffa19b;
    color: #eb5b51;
    border: #d6534a 1px solid;
}

.catContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 10px 10px;
}

.catContainer i {
    cursor: pointer;
    margin-left: 15px;
}

.catContainer input {
    padding: 2px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 15px 10px 15px;
}

.pagination .pages .pageNumber{
    padding: 5px 8px 5px 8px;
    border: 1px #6c757d solid;
    margin: 5px;
    cursor: pointer;
}

.pagination .pages .pageNumber.current{
   color: #6c757d;
}

.pagination .total {

}

.formRow {
    margin-bottom: 8px;
}

.formLabel {
    display: inline-block;
    text-align: left;
    width: 120px;
}

.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(20, 83, 136, 0.2);
    border-radius: 50%;
    border-top-color: #145388;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
    z-index: 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.customSelect {
    padding: 5px;
    background-image:none;
    margin-left: 10px;
}

.customSelect option {
    padding: 5px;
}

.k-animation-container{
    z-index: 10003
}

.codeLink a{
    color: #002e79;
    text-decoration: underline;
}

.vertical-form {
    display: flex;
    flex-wrap: wrap;
}

.form-group {
    display: flex;
    margin-bottom: 8px;
}

.form-group label {
    width: 120px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}