.container {
    font-family: 'irsans',Arial, Helvetica, sans-serif;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: right;
    width: 90%;
}
.adress {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 5px;
    text-align: center;
    width: 90%;
}
.tel {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    text-align: center;
    width: 90%;
}
.sign{
    display: flex;
    justify-content: space-around;
}
.div-table {
    text-align: center;
}
#customers {
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;

  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #cdcece;
    color: rgb(0, 0, 0);
  }
  .header-crm {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
 .Label-crm{
    margin-left: 10px;
 } 
 .adress-customer{
    background: #f2f2f2;
    margin-bottom: 10px;
    font-size: 11px;
    border-radius: 21px;
    padding: 10px;
    padding-right: 14px;
 }

 @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @page {
    size: auto;
    margin: 15mm;
  }