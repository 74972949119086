@font-face {
    font-family: 'irsans_b';
    src: url('IRANSansMobile-Bold.eot');
    src: url('IRANSansMobile-Bold.eot?#iefix') format('embedded-opentype'),
        url('IRANSansMobile-Bold.woff') format('woff'),
        url('IRANSansMobile-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'irsans_m';
    src: url('IRANSansMobile-Medium.eot');
    src: url('IRANSansMobile-Medium.eot?#iefix') format('embedded-opentype'),
        url('IRANSansMobile-Medium.woff') format('woff'),
        url('IRANSansMobile-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'irsans_ul';
    src: url('IRANSansMobile-UltraLight.eot');
    src: url('IRANSansMobile-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('IRANSansMobile-UltraLight.woff') format('woff'),
        url('IRANSansMobile-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'irsans';
    src: url('IRANSansMobile.eot');
    src: url('IRANSansMobile.eot?#iefix') format('embedded-opentype'),
        url('IRANSansMobile.woff') format('woff'),
        url('IRANSansMobile.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'irsans_l';
    src: url('IRANSansMobile-Light.eot');
    src: url('IRANSansMobile-Light.eot?#iefix') format('embedded-opentype'),
        url('IRANSansMobile-Light.woff') format('woff'),
        url('IRANSansMobile-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}