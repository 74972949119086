
.loginContainer {
    margin: 0;
    background: lightsteelblue;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.loginWrapper {
    width: 400px;
    background: #ddd;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    direction: rtl;
    border: 1px #aaa solid;
    margin: 40px auto 0 auto;
    border-radius: 25px;
}

.loginWrapper form{
    padding-bottom: 40px;
    padding-top: 10px;
}
.loginWrapper .logo{
    text-align: center;
    margin-top: 8px;
}